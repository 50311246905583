if ($('#stripe').length) {
    const stripe = Stripe(document.getElementById('stripe-key').value);
    const elements = stripe.elements({locale: 'en'});
    let style = {}
        if ($(window)[0].innerWidth <= 650) {
            style = {
                base: {
                    color: '#1a4487',
                    fontWeight: '500',
                    fontFamily: 'Roboto,serif',
                    fontSize: '12px',
                    textAlign: 'center'
                }
            }
        }else{
            style = {
                base: {
                    color: '#1a4487',
                    fontWeight: '500',
                    fontFamily: 'Roboto,serif',
                    fontSize: '24px',
                    textAlign: 'center'
                }
            }
        }
    const cardElement = elements.create('cardNumber', {
        style: style
    });
    cardElement.mount('#stripe-card');
    const cardExpiryElement = elements.create('cardExpiry', {
        style: style
    });
    cardExpiryElement.mount('#card-expiry-element');
    cardExpiryElement.mount('#card-expiry-element');
    const cardCvcElement = elements.create('cardCvc', {
        style: style,
        placeholder:'CVV/CVC/CID'
    });
    cardCvcElement.mount('#card-cvc-element');

    // const cardHolderName = document.getElementById('card_holder');

    $('#card_holder').on('input', function (event) {
        setOutcome(event);
    })

    cardElement.on('change', function (event) {
        setOutcome(event);
    });
    cardExpiryElement.on('change', function (event) {
        setOutcome(event);
    });
    let form = document.getElementById('stripe')
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        $('#card-button').prop('disabled', true);
        stripe.createPaymentMethod('card', cardElement, {
            billing_details: {name: $('#card_holder').val()},
        }, {
            description: 'morhayq'
        }).then(setOutcome);
    });


    function setOutcome(result) {
        let errorElement = document.querySelector('.error');
        errorElement.classList.remove('visible');
        console.log(result)
        if (result.error) {
            if (result.error.code !== "parameter_invalid_empty") {
                errorElement.textContent = result.error.message;
                errorElement.classList.add('visible');
            } else {
                errorElement.textContent = 'Cardholder can not be empty';
                errorElement.classList.add('visible');
            }
            $('#card-button').prop('disabled', false);
        } else if (result.paymentMethod) {
            $('#payment_method').val(result.paymentMethod.id)
            $('#card_type').val(result.paymentMethod.card.brand)
            form.submit()
        }

        // stripe.createPaymentMethod(
        //     'card', cardElement, {
        //         billing_details: {name: $('#card_holder').val()},
        //     }, {
        //         description: 'morhayq'
        //     }
        // ).then((response) => {
        //     // let secret = $('#card-button').attr('data-secret')
        //     // console.log(secret)

        //     // stripe.confirmCardSetup(secret, {
        //     //         payment_method:
        //     //             {
        //     //                 card: cardElement,
        //     //                 billing_details:
        //     //                     {
        //     //                         name: $('#card_holder').val()
        //     //                     }
        //     //             }
        //     //     }
        //     // ).then(function (response) {
        //     //     console.log('pix', response)
        //     //     if (response.error) {
        //     //         console.log('pix', response, 'pxer')
        //     //     } else if (response.paymentItent && response.paymentItent.status === 'successed') {
        //     //         console.log('pix', response, 'asd')
        //     //
        //     //     }
        //     // })

        //
        // }).catch(err => {
        //     console.log(err)
        // })
    }
}
