import {validatorResponse} from "./helpers/responseDataValidate";
import stripe from "./helpers/stripe"
import donate from "./helpers/donate"
import axios from "axios";

$('document').ready(function () {

    //login
    $('#becomeDonor').click(function () {
        openSignUpModal()
    })
    $('#logIn').click(function () {
        openLoginModal()
    })
    $('#logInMedia').click(function () {
        openLoginModal()
        $('.side-nav').css('right', '-80vw')
        $('.background-button').css('display', 'none')
    })

    $('.logInForm').submit(function (e) {
        e.preventDefault()
        $(this).find('button').prop('disabled', true)
        let key = $('#login-key').val()
        grecaptcha.ready(function () {
            grecaptcha.execute(key, {action: 'submit'}).then(function (token) {
                $('#login-key').val(token)
                $('.logInForm').unbind().submit()
            }).catch((err) => {
                console.log(err.response)
            });
        });

    })

    //send verify mail

    $('#send-verify-mail').click(function () {
        $('#send-verify-mail').prop('disabled', true);
        axios.post('email/verification-notification').then((response) => {
            $('#sent').css('display', 'block')
            setTimeout(() => {
                $('#sent').css('display', 'none')
                $('#send-verify-mail').prop('disabled', false);
            }, 60000)
        }).catch((err) => {
            $('#send-verify-mail').prop('disabled', false);
            console.log('error', err.response.data)
        })
    })

    //close-btn
    $('.background-button').click(function () {
        $('.side-nav-user').css('right', '-80vw')
        $('.side-nav').css('right', '-80vw')
        $('body').css('overflow', 'auto')
            .css('height', 'unset')
        $('.background-button').css('display', 'none')
        $('.modal-section').find('input').each(function () {
            if ($(this).attr('type') !== 'hidden') {
                $(this).val('')
            }
        })
        $('.modal-section').find('.error').each(function () {
            $(this).removeClass('error')
        })
        $('.validationMail').html('')
        $('.validationPassword').html('')
        $('.validationConfirm').html('')
        $('.validationErrorsFirst').html('')
        $('.login-error').html('')
    })
    $('.close-all').click(function () {
        closeSuccess()
    })
    $('.modal-background').click(function () {
        closeSuccess()
    })
    // $('.success-close').click(function () {
    //     closeSuccess()
    // })

    // frontSidebar
    $('#open-side-nav').click(function () {
        $('.side-nav').css('right', '0')
        $('body').css('overflow', 'hidden')
            .css('height', '100vh')
        $('.background-button').css('display', 'block')
    })
    $('#close-side-nav').click(function () {
        $('.side-nav').css('right', '-80vw')
        $('body').css('overflow', 'auto')
            .css('height', 'unset')
        $('.background-button').css('display', 'none')
        $('.modal-section').find('input').each(function () {
            if ($(this).attr('type') !== 'hidden') {
                $(this).val('')
            }
        })
        $('.modal-section').find('.error').each(function () {
            $(this).removeClass('error')
        })
        $('.validationMail').html('')
        $('.validationPassword').html('')
        $('.validationConfirm').html('')
        $('.validationErrorsFirst').html('')
        $('.login-error').html('')
    })
    $('#signUp').click(function () {
        openSignUpModal()
    })
    $('#signUpMedia').click(function () {
        openSignUpModal()
        $('.side-nav').css('right', '-80vw')
        $('.background-button').css('display', 'none')
    })


    //reset password

    $('#forgetBtn').click(function () {
        $('.logInForm').removeClass('active')
        $('.left-img').find('.active').each(function () {
            $(this).removeClass('active')
        })
        $('.resetPassword').addClass('active')
    })
    //


    $('.Password_confirming').find('input').each(function () {
        $(this).on('input', function () {
            $('.validationErrorsFirst').empty()
            $('.modal-section').find('.error').each(function () {
                $(this).removeClass('error')
            })
        })
    })
    $('.Password_confirming').submit(function (e) {
        e.preventDefault()
        let password = $('#password-reset')
        let confirm = $('#password_confirmation')
        if (password.val().length < 8) {
            $('.validationErrorsFirst').append('The password field is required and min 8 symbols<br>')
            $('label[for=password-reset]').addClass('error')
            return
        }
        if (confirm.val() !== password.val()) {
            $('.validationErrorsFirst').append('Passwords do not match')
            $('label[for=password_confirmation]').addClass('error')
            return
        }
        let form = document.getElementsByClassName('Password_confirming')[0]
        let data = new FormData(form)
        axios.post(form.action, data).then((response) => {
            if (response.status === 200) {
                window.location.href = 'dashboard'
            }
        }).catch(error => {
            $('.validationErrorsFirst').empty().append(validatorResponse(error.response.data.errors))
        })
    })
    let passwordResetData;
    let passwordResetForm;
    $('.resetPassword').submit(function (e) {
        e.preventDefault()
        let form = document.getElementsByClassName('resetPassword')[0]
        let data = new FormData(form)
        passwordResetForm = form
        passwordResetData = data
        axios.post(form.action, data).then((response) => {
            if (response.status === 200) {
                closeSuccess()
                checkEmail('forget')
            } else {
                $('.validationErrorsFirst').empty().html(response.data)
            }
        }).catch(error => {
            $('.validationErrorsFirst').empty().append(validatorResponse(error.response.data.errors))
        })
    })

    //send password again
    $('#send-password-mail').click(function () {
        $('#send-password-mail').prop('disabled', true);
        axios.post(passwordResetForm.action, passwordResetData).then((response) => {
            $('#sent-password').css('display', 'block')
            setTimeout(() => {
                $('#sent-password').css('display', 'none')
                $('#send-password-mail').prop('disabled', false);
            }, 60000)
        }).catch((err) => {
            $('#send-password-mail').prop('disabled', false);
            console.log('error', err.response.data)
        })
    })

    //register
    $('.sign-up-form').find('input').each(function () {
        $(this).on('input', function () {
            $('.validationErrorsFirst').empty()
            removeErr()
            $('.modal-section').find('.error').each(function () {
                $(this).removeClass('error')
            })
        })
    })
    confirm.change(function () {
        removeErr()
        confirmF()
    })
    password.change(function () {
        removeErr()
        passwordF()
    })
    mail.change(function () {
        removeErr()
        mailF()
    })
    $('#register-next').click(function () {
        mailF()
        passwordF()
        confirmF()
        next()
    })
    $('#register-back').click(function () {
        $('.second').removeClass('active')
        $('.first').addClass('active')
    })
    let today = new Date();
    let date = (today.getMonth() + 1 > 9 ? '' : '0') + (today.getMonth() + 1) + '/' + (today.getDate() > 9 ? '' : '0') + today.getDate() + '/' + today.getFullYear();
    $('#birthday').daterangepicker({
        "singleDatePicker": true,
        "showDropdowns": true,
        "linkedCalendars": false,
        "autoUpdateInput": false,
        "showCustomRangeLabel": false,
        "alwaysShowCalendars": true,
        "autoApply": true,
        "startDate": date,
        "maxDate": date,
        "endDate": date,
    }, function (start, end, label) {
        // console.log(date,'New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        $('#birthday').val(start.format('DD.MM.YYYY'))
    });
    $('#register').click(function (e) {
        e.preventDefault()
        $(this).prop('disable', true)
        if ($('#bio').val() === '' && $('#full_name').val()) {
            $('#bio').val(' ')
        }

        let form = document.getElementsByClassName('sign-up-form')[0]
        let data = new FormData(form)
        let key = $('#sign-key').val()
        grecaptcha.ready(function () {
            grecaptcha.execute(key, {action: 'submit'}).then(function (token) {
                $('#sign-key').val(token)
                data.append('recaptcha', token)
                axios.post(form.action, data).then((response) => {
                    if (response.status === 200) {
                        closeSuccess()
                        window.location.href = '/#verifyEmail'
                        if (window.location.pathname === '/') {
                            window.location.reload(true)
                        }
                    }
                }).catch(error => {
                    $('#sign-key').val(key)
                    $(this).prop('disable', false)
                    console.log(error.response)
                    if (error.response.data.errors.name) {
                        $('label[for=full_name]').addClass('error')
                    }
                    if (error.response.data.errors.birthday) {
                        $('label[for=birthday]').addClass('error')
                    }
                    $('.validationErrorsFirst').empty().append(validatorResponse(error.response.data.errors))
                })
            });
        });
    })

    //stay-informed
    $('#stay-informed-form').submit(function (e) {
        e.preventDefault()
        let data = new FormData(e.target)
        axios.post(e.target.action, data).then((response) => {
            if (response.status === 200) {
                $('.modal-section').addClass('active')
                $('.modal-success').addClass('active')
                $('.touch').addClass('active')
            }
            if (response.status === 201) {
                console.log(response.data)
                $(this).find('p').show().html(response.data.email)
            }

        }).catch((e) => {
            console.log(e)

        })
    })
    $('#informed-input').on('input', function () {
        $(this).parent().parent().find('p').hide()
    })

    //dropdown settings
    $('#logOutSettings').click(function () {
        $(this).parent().find('.options').toggleClass('active')
    })

    //two factor
    $('.key_confirm').submit(function () {
        $(this).find('.send').prop('disabled', true)
    })


    //href functions
    if (window.location.href.includes('#login')) {
        openLoginModal()
    }
    if (window.location.href.includes('#sign-up')) {
        openSignUpModal()
    }
    if (window.location.href.includes('#informed')) {
        openSuccess('informed')
    }
    if (window.location.href.includes('#verifyEmail')) {
        checkEmail('check')
    }
    if (window.location.href.includes('#reset-password')) {
        $('.modal-section').addClass('active')
        $('.logInRegisterModal').addClass('active')
        $('.logIn').addClass('active')
        $('.Password_confirming').addClass('active')
        $('body').css('overflow', 'hidden')
            .css('height', '100vh')
    }
    if (window.location.href.includes('#twoFactor')) {
        $('.modal-section').addClass('active')
        $('.logInRegisterModal').addClass('active')
        $('.logIn').addClass('active')
        $('.key_confirm').addClass('active')
        $('body').css('overflow', 'hidden')
            .css('height', '100vh')
    }
    if (window.location.href.includes('#deactivated')) {
        openSuccess('deactivated-successful')
    }
    if (window.location.href.includes('#payment-successful')) {
        openSuccess('payment-successful')
    }
    if (window.location.href.includes('#payment-failed')) {
        openSuccess('payment-failed')
    }
    if (window.location.pathname === '/DonateFirstStep' || window.location.pathname === '/DonateSecondStep') {
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.donate-steps').offset().top
        }, 200);
    }
// element check
    let element = document.activeElement.tagName == "INPUT"
    if (element) {
        element.scrollIntoViewIfNeeded(true)
    }
    if (/Android/.test(navigator.appVersion)) {
        window.addEventListener("resize", function () {
            if (document.activeElement.tagName == "INPUT") {
                window.setTimeout(function () {
                    document.activeElement.scrollIntoViewIfNeeded();
                }, 0);
            }
        })
    }

})


function openLoginModal() {
    $('.modal-section').addClass('active')
    $('.logInRegisterModal').addClass('active')
    $('.logIn').addClass('active')
    $('.logInForm').addClass('active')
    $('.left-img').find('h3').addClass('active')
    $('.left-img').find('button').addClass('active')
    $('.grecaptcha-badge').addClass('active')
    $('body').css('overflow', 'hidden')
        .css('height', '100vh')
}

function openSuccess(option) {
    $('.modal-section').addClass('active mobile')
    $('.modal-success').addClass('active mobile')
    if (option === 'informed') {
        $('.touch').addClass('active mobile')
    } else if (option === 'payment-successful') {
        $('.payment-successful').addClass('active mobile')
    } else if (option === 'deactivated-successful') {
        $('.deactivated-successful').addClass('active mobile')
    } else if (option === 'payment-failed') {
        $('.payment-failed').addClass('active mobile')
    }
    $('body').css('overflow', 'hidden')
        .css('height', '100vh')
}

function closeSuccess() {
    $('.modal-section').removeClass('active mobile').find('.active').each(function () {
        $(this).removeClass('active')
    })
    $('.modal-section').removeClass('mobile').find('.mobile').each(function () {
        $(this).removeClass('mobile')
    })
    $('.grecaptcha-badge').removeClass('active')
    $('body').css('overflow', 'auto')
        .css('height', 'unset')
    $('.modal-section').find('input').each(function () {
        if ($(this).attr('type') !== 'hidden') {
            $(this).val('')
        }
    })
    $('.modal-section').find('.error').each(function () {
        $(this).removeClass('error')
    })
    $('.modal-background').css('display', 'block')
    $('.modal-success').css('min-height', '621px')
    $('.validationMail').html('')
    $('.validationPassword').html('')
    $('.validationConfirm').html('')
    $('.validationErrorsFirst').html('')
    $('.login-error').html('')
}

function openSignUpModal() {
    $('.left-img').find('.active').each(function () {
        $(this).removeClass('active')
    })
    $('.left-img').find('p').addClass('active')
    $('.modal-section').addClass('active')
    $('.logInRegisterModal').addClass('active')
    $('.signUp').addClass('active')
    $('.first').addClass('active')
    $('.sign-up-form').addClass('active')
    $('.grecaptcha-badge').addClass('active')
    $('body').css('overflow', 'hidden')
        .css('height', '100vh')
}

function checkEmail(param) {
    $('.modal-section').addClass('active mobile')
    $('.logInRegisterModal').addClass('active mobile')
    $('.signUp').addClass('active mobile')
    if (param === 'check') {
        $('.registered').addClass('active mobile')
    } else if (param === 'checked') {
        $('.check').addClass('active mobile')
    } else if (param === 'forget') {
        $('.forget').addClass('active mobile')
    }

}


$('#send-again').click(function () {
    axios.post('/verify/resend')
})


let password = $('#password')
let confirm = $('#password_confirm')
let mail = $('#email')
let registered = false
let email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function confirmF() {
    let validate = $('.validationConfirm')
    validate.empty()
    $('.modal-section').find('label[for=password_confirm]').each(function () {
        $(this).removeClass('error')
    })
    if (confirm.val() !== password.val()) {
        validate.append('Passwords do not match')
        $('label[for=password_confirm]').addClass('error')
    }
}

function passwordF() {
    let validate = $('.validationPassword')
    validate.empty()
    $('.modal-section').find('label[for=password]').each(function () {
        $(this).removeClass('error')
    })
    if (password.val().length < 8) {
        validate.append('The password field is required and min 8 symbols<br>')
        $('label[for=password]').addClass('error')
    }
}

function mailF() {
    let validate = $('.validationMail')
    if (mail.val().match(email)) {
        axios.post('/check/email', {'email': mail.val()}).then((response) => {
            $('.modal-section').find('label[for=email]').each(function () {
                $(this).removeClass('error')
            })
            registered = false
        }).catch((err) => {
            registered = true
            validate.empty()
            validate.append('The email has already registered<br>')
            $('label[for=email]').addClass('error')
        })
    } else {
        if (!mail.val().match(email)) {
            validate.empty()
            validate.append('The email field is required<br>')
            $('label[for=email]').addClass('error')
        }
    }
}

function next() {
    if (mail.val().match(email) && password.val().length > 7 && confirm.val() === password.val() && !registered) {
        $('.first').removeClass('active')
        $('.second').addClass('active')
    }
}

function removeErr() {
    $('.validationConfirm').empty()
    $('.validationPassword').empty()
    $('.validationMail').empty()
}

//boards click
$('.boards-section').find('.more').each(function () {
    $(this).click(function () {
        console.log($(this))
        let name = $(this).find('h2').html()
        let img = $(this).find('img').attr('src')
        let des = $(this).find('p').html()
        let modal = $('.board-modal')
        // $('.modal-background').css('display', 'none')
        $('.modal-section').addClass('active')
        $('body').css('overflow', 'hidden')
            .css('height', '100vh')
        console.log(window.innerHeight)
        console.log(window)
        if(window.innerHeight>821){
            $('.modal-success').addClass('active').css('min-height', '821px').css('width','48.0769230769vw')
            modal.find('p').css('height','auto').css('max-height','350px')
            modal.find('button').css('display','flex')
        }else{
            $('.modal-success').addClass('active').css('min-height','100vh').css('width','100vw').css('border-radius','unset')
            modal.find('p').css('height','50vh').css('min-height','unset')
            modal.find('button').css('display','none')

        }
        modal.addClass('active')
        modal.find('.board-modal-header').find('img').attr('src', img)
        modal.find('.board-modal-header').find('h2').html(name)
        modal.find('p').html(des)
    })
})
$( window ).resize(function() {
    if($(window.innerWidth<700)){
        $('.logInRegisterModal').find('input').each(function (){
            $(this).focus(function (){
                $('.logInRegisterModal').find('.wrapper').css('margin-top','-170px')
            }).blur(function (){

                $('.logInRegisterModal').find('.wrapper').css('margin-top','0')
            })
        })
    }else{
        $('.logInRegisterModal').find('input').each(function (){
            $(this).focus(function (){
                $('.logInRegisterModal').find('.wrapper').css('margin-top','0')
            })
        })
    }
});

