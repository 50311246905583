if($('.form-1').length){
    $('#amount5').on('input',function (){
        if($(this).val() === ''){
            $('#amount1').prop('checked',true)
        }else {
            $('.amount-inp').each(function (){
                $(this).prop('checked',false)
            })
        }
    })
    $('.amount-inp').change(function (){
        $('#amount5').val('')
    })
}
if($('.form-2').length){
    $('#amount5').on('input',function (){
        if($(this).val() === ''){
            $('#amount1').prop('checked',true)
        }else {
            $('.amount-inp').each(function (){
                $(this).prop('checked',false)
            })
        }
    })
    $('.amount-inp').change(function (){
        $('#amount5').val('')
    })
}
